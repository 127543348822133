:root {
	interpolate-size: allow-keywords;
}
.custom-scroll {
	scroll-behavior: smooth !important;
}
.custom-scroll::-webkit-scrollbar {
	width: 4px;
}

.custom-scroll.zero-scroll::-webkit-scrollbar {
	width: 0;
}

.custom-scroll::-webkit-scrollbar-track {
	background: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
	background: rgb(229 231 235);
	border-radius: 0.75rem;
}

div:has(> .dropdown-empreender) {
	z-index: 2 !important;
}

.base-Slider-mark:not(:has(~ .base-Slider-mark)) {
	left: calc(100% - 3px) !important;
}

.base-Slider-root .base-Slider-mark[data-index='0'] {
	left: 3px !important;
}

/* [data-radix-popper-content-wrapper] {
	z-index: 10 !important;
}
[data-radix-popper-content-wrapper] {
	z-index: 10 !important;
} */

.w-color-sketch {
	padding: 16px !important;
	flex-shrink: 0 !important;
	box-shadow: 0px 10px 15px 0px rgba(31, 41, 55, 0.1), 0px 0px 6px 0px rgba(31, 41, 55, 0.05) !important;
	border-radius: 8px !important;
}

.w-color-sketch > div {
	padding: 0 !important;
}

.w-color-interactive {
	border-radius: 4px !important;
}

.w-color-sketch > div div:has(.w-color-alpha) {
	margin: 0px !important;
}

.w-color-sketch > div div:has(.w-color-alpha) > div {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.w-color-interactive.w-color-saturation {
	margin-bottom: 16px !important;
}

.w-color-sketch > div > div:has(.w-color-alpha) > div:nth-of-type(2) {
	display: none !important;
}

.w-color-sketch > div > div:has(.w-color-alpha) > div > div:nth-of-type(2) {
	margin: 0px !important;
	background-size: 5px !important;
	border-radius: 8px !important;
}

.w-color-sketch > div > div:has(.w-color-alpha) > div > div:nth-of-type(1) {
	background: transparent !important;
}

.w-color-sketch > div > div:has(.w-color-alpha) > div > div:nth-of-type(2), .w-color-sketch > div > div:has(.w-color-alpha) > div > div:nth-of-type(1) {
	height: 8px !important;
}

.w-color-sketch > div > div:has(.w-color-alpha) > div > div:nth-of-type(2) > div, .w-color-sketch > div > div:has(.w-color-alpha) > div > div:nth-of-type(1) > div {
	border-radius: 8px !important;
}

.w-color-interactive:not(.w-color-saturation) > div {
	box-shadow: none !important;
    width: 10px !important;
    height: 10px !important;
    border-radius: 9999px !important;
    transform: translate(-5px, -2px);
    background: transparent !important;
    border: 2px solid #fff;
    filter: drop-shadow(0px 4px 6px rgba(31, 41, 55, 0.10)) drop-shadow(0px 2px 4px rgba(31, 41, 55, 0.06));
}

.w-color-saturation-fill {
	box-shadow: rgb(255 255 255) 0px 0px 0px 1.5px, rgb(250 0 0 / 0%) 0px 0px 1px 1px inset, rgb(0 0 0 / 0%) 0px 0px 1px 2px !important;
}

.w-color-sketch > div:nth-of-type(2)  {
	margin: 16px 0px 0px 0px !important;
}

.w-color-sketch > div:nth-of-type(2) input {
	outline: none !important;
	border-radius: 4px !important;
	border-width: 1px !important;
	border-color: #E5E7EB !important;
	border-style: solid !important;
	box-shadow: none !important;
	text-align: center;
}

.w-color-sketch > div:nth-of-type(2) input ~ span {
	display: none;
}